.containerFooter{
    display:flex;
    flex:1 0 0%;
    height:184px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1B3541;
}
.topFooter{
    display:flex;
    height:184px;
    width:100%;
    flex-direction:row;
    max-width:1300px;
    justify-content:space-between;
    align-items:center;
}


.socialFooter, .legalFooter, .usFooter, .contactFooter, .appFooter {
    display:flex;
    width: 22%;
    flex-direction: column;
}
.socialFooter{
    width: 34%;
}

.buttonText{
    text-transform: initial !important;
    color: black !important;
    font-family: Urbanist !important;
    font-size: 16px !important;
    opacity: 0.5;

}
.fontBold{
    font-weight: 600 !important;
    opacity: 1 !important;
}
.socialIcons {
    display: flex;
    flex-direction: revert;
    justify-content: center;
}

.social-logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.footer-label-regular {
    height: 71px;
    justify-content: flex-start;
    display: flex;
    align-items: flex-end;
    font-family: 'Graphik-Regular';
    color: #aa9461;
    font-size: 16px;
    position: relative;
}
.footer-label-bold {
    justify-content: flex-start;
    display: flex;
    align-items: flex-end;
    font-family: 'Graphik-Regular';
    color: #aa9461;
    font-size: 16px;
    font-weight: 900;
    position: relative;
    margin-top: 7px;
}


.footer-label-regular::after {
    content: '';
    width: 3px;
    height: 10px;
    left: -8px;
    bottom: 5px;
    position: absolute;
    background-color: #aa9461;
}
.footer-label {
    text-align: left;
    font-family: 'Graphik-Regular';
    color: #aa9461;
    margin-top: 7px;
}
.legalFooter .footer-label-bold,
.usFooter .footer-label-bold {
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    font-family: 'Graphik-Regular';
    color: #aa9461;
    font-size: 16px;
    font-weight: 900;
    position: relative;
    height: 43px;
    margin-top: 7px;
}
.request-buttons {
    height: 43px;
    width: 100%;
    margin-top: 7px;
    display: flex;
    justify-content: flex-start;
}

.request-buttons button:last-child{
    margin-left: 8px;
}
.request-buttons button {
    width: 120px;
    height: 26px;
    background-color: #8e8e8e52;
    font-family: 'Graphik-Regular' !important;
    color: #808080 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}
@media only screen and (max-width:1024px){
    .containerFooter {
        height: auto;
    }
    .topFooter {
        height: auto;
        margin-top: 60px;
        flex-direction: column;
        margin-bottom: 60px;
    }
    .socialFooter, .legalFooter, .usFooter, .contactFooter, .appFooter {
        margin-top: 40px;
    }
}