.free-booking-container {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.free-booking-container .booking-component{
    display:flex;
    text-align: center;
    box-sizing:border-box;
    position: relative;
    width: 460px;
    height: 664px;
    background: #FFFFFF;
    border: 1px solid black;
    border-radius: 10px;
    flex-direction: column;
}
.modal-register {
    height: 100%;
    width: 100%;
    position: relative;
}
.step {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}
.step-form form.form {
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    height: 100%;
}
.step-form {
    display: flex;
    flex-direction: column;
    padding: 50px;
    flex: 1 0 1%;
}
.step-form button.loginBtn {
    align-self: center;
}
.step-navigator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.step-marker {
    height: 120px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-bottom: 24px;
    /* align-self: center; */
}
.step-mark {
    height: 12px;
    width: 12px;
    background-color: rgb(201, 201, 201);
    border-radius: 50%;
}
.step-mark.active{
    background-color: #101820;
}
.free-booking-container .step .back-header {
    display: flex;
    height: 18px;
}
.stepBtn {
    position: absolute;
    top: 20px;
    left: 20px;
}
.form-group.area {
    height: 200px;
}
textarea.text-area-tf {
    width: 420px;
    border-radius: 10px;
    height: 156px;
    resize: none;
    font-size: 16px;
    padding: 20px 14px;
    box-sizing: border-box;
    font-family: 'Urbanist';
    border: #8080807a solid 1px !important;
    outline: unset;
    color: #9f9f9f;
    background-color: transparent;
    /* overflow-y: scroll; */
}
.back-btn button {
    height: 28px;
    width: 28px;
}