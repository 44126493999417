@font-face {
  font-family: "Urbanist";
  src: url("./assets/fonts/Urbanist-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Urbanist-Bold";
  src: url("./assets/fonts/Urbanist-Bold.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Urbanist-SemiBold";
  src: url("./assets/fonts/Urbanist-SemiBold.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Urbanist-Light";
  src: url("./assets/fonts/Urbanist-Light.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("./assets/fonts/GraphikRegular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Graphik-Bold";
  src: url("./assets/fonts/GraphikBold.otf") format("opentype");
  font-weight: 900;
}
@font-face {
  font-family: "Graphik-Semibold";
  src: url("./assets/fonts/GraphikSemibold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Graphik-Regular-Italic";
  src: url("./assets/fonts/GraphikRegularItalic.otf") format("opentype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay !important;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
