.profile-container {
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

}
    
.top-container {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0%;
    width: 100%;
    background-color: #EDEDED;
}

.wrapper {
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex: 1 0;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
}
.middle-container {
    width: 640px;
    display: flex;
    flex-direction: column;
    height: 200px;
}
.middle-top {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 6px;
}
.middle-top-item {
    display: flex;
    flex-direction: row;
    height: 46px;
    justify-content: flex-start;
    align-items: center;
}
.middle-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 1px solid gray;
    height: 100px;
    padding: 0 30px;
    box-sizing: border-box;
}
.floating-icon {
    position: absolute;
    right: 60px;
    top: 20px;
}
.profile-container .image-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.left-container {
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    width: 42px;
}
.right-container {
    display: flex;
    flex-direction: column;
}
.cartera-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 420px;
    height: 40px;
    background-color: white;
    border-radius: 25px;
    padding: 0 20px;
    box-sizing: border-box;
}
.cartera-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 130px;
}
.puntos-row {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 310px;
    background-color: white;
    border-radius: 25px;
    align-self: flex-end;
    margin-top: 14px;
    margin-bottom: 14px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}
.puntos-row .normal-label {
    width: 84px;
    text-align: center;
}
.category-row {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    justify-content: space-between;
    width: 310px;
    height: 50px;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    align-items: center;
}
.cat-item {
    border-radius: 25px;
    box-sizing: border-box;
    border: 3px solid #5D5FEF;
    height: 50px;
    width: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.sales-indactor {
    justify-content: center;
    align-items: center;
}
.sales-indactor,
.ux-indicator,
.envoy-indicator{
    display: flex;
    flex-direction: column;
    height: 80px;
}
.ux-indicator{
    width: 200px;
}
.sale-item {
    display: flex;
    align-items: center;
    border: 3px solid #26d07c;
    border-radius: 25px;
    padding: 6px;
    box-sizing: border-box;
    height: 44px;
    /* width: 96px; */
    justify-content: space-around;
}
.top-container .middle-bottom .normal-label {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sales-item,
.stars-container,
.time-container{
    display: flex;
    height:60px;
    align-items: center;
    justify-content: center;
}
.time-item {
    display: flex;
    align-items: center;
    justify-content: center;
}
.time-label {
    margin-left: 10px;
}
span.MuiRating-root.MuiRating-sizeLarge.MuiRating-readyOnly span {
    margin-right: 14px;
}
span.MuiRating-root.MuiRating-sizeLarge.MuiRating-readyOnly span:last-child {
    margin-right: 0px;
}
.sale-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-item.active {
    border: 1px solid #baa261;
    border-radius: 10px;
    background-color: #ffffff0f;
}
.menu-item:hover{
    background-color: #ffffff0f;
}
.container-menu .back-trade {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    opacity: 0.8;
    /* color: white; */
}