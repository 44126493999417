.navbar-container-logout{
    display: flex;
    height:130px;
    flex:1 0 0%;
    background-color:#1B3541;
    color:white;
    justify-content:center;
    align-items:center;
    z-index:1;
    position: relative;
    width:100%;
    opacity:0.75;
    transition: all 250ms ease-in-out;
}
.navbarBg {
    height: 130px;
    width: 100%;
    background: #1B3541;
}
.navbarContent {
    display: flex;
    flex: 1 0;
    flex-direction: row;
    max-width: 1300px;
    justify-content: center;
    padding: 20px 0;
    box-sizing: border-box;
    height: 100%;
}
.menuLeft{
    display: flex;
    flex-direction: row;
}
.menuRight{
    display: flex;
    flex-direction: row;
}
.navbarItem{
    display: flex;
    padding: 0 20px;
    align-items:center;
    justify-content: center;
    cursor: pointer;
}
.menuLeft .navbarItem:first-child {
    padding: 0 20px 0 0;
} .menuRight .navbarItem:last-child  {
    padding: 0 0 0 20px;
}
.centeredText{
    position: absolute;
    transform:translate(-50%,-50%);
    top:50%;
    left:50%
}


.back-trade {
    width: 280px;
    height: 40px;
    align-self: flex-end;
    /* color: white; */
}
.back-trade a{
    color: white;
    text-decoration: none;
    font-family: 'Graphik-Regular';
    font-size: 12px;
}
a.navbar-link {
    color: #AA9461;
    font-size: 12px;
    font-family: 'Graphik-Regular';
    text-decoration: none;
}
.navbar-links-container {
    flex-direction: row;
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 280px;
}
.separator {
    height: 10px;
    width: 2px;
    margin-top: 3px;
    background-color: #aa9461;
}
.nav-links {
    width: 80px;
}
.navbarContent .login-header-container{
    height: 90px;
}