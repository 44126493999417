
.login-container {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.bg-container-mosaico {
    position: absolute;
    width: 100%;
    height: 750px;
}
.bg-container-mosaico img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.content-login{
    display:flex;
    text-align: center;
    box-sizing:border-box;
    position: relative;
    width: 460px;
    height: 544px;
    background: #FFFFFF;
    border: 1px solid black;
    border-radius: 10px;
    flex-direction: column;
}
.login-header-container {
    background-color: #1B3541;
    height: 129px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo-container {
    width: 205px;
    height: 67px;
    position: relative;
    border-bottom: 2px solid #A18E5F;
    box-sizing: border-box;
}
img.trade-roofing-logo {
    width: 205px;
    height: 67px;
    object-fit: contain;
}
.logo-text {
    font-family: 'Graphik-Regular';
    text-transform: uppercase;
    color: #AA9461;
    margin-top: 5px;
    font-size: 16px;
}
.loginTitle{
    font-weight: 600;
    font-size: 24px;
    padding-top: 18px;
}
.containerTop{
    display:flex;
    flex:1 0 0%;
    flex-direction: column;
}
.containerBottom{
    display:flex;
    flex:1 0 0%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.content-login form.form {
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    padding: 30px 40px;
    display: flex;
    height: 415px;
    justify-content: flex-start;
}
.form-content-container{
    display:flex;
    flex:1 0 0%;
    flex-direction: column;

}
.form-group {
    width: 100%;
    height: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-group.column {
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    /* height: 80px; */
}
.form-group.button-bottom {
    /* justify-self: flex-end; */
    align-self: center;
    bottom: 45px;
    position: absolute;
    height: auto;

}
.loginBtn {
    background-color: #BAA261 !important;
    width: 165px;
    height: 48px;
    padding: 0;
    font-family: 'Graphik-Regular' !important;
    color: white !important;
    text-transform: initial !important;

}
.loginBtn input[type="submit"] {
    background: transparent;
    border: unset;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 16px;
    font-family: 'Graphik-Regular';
    cursor: pointer;
}
a.nav-link {
    text-decoration: none;
    font-family: 'Graphik-Regular';
    color: black;
    font-size: 14px;
    font-weight: 600;
}
label#outlined-basic-email-label ,
label#outlined-basic-pass-label {
    font-family: 'Graphik-Regular' !important;
}
.login-input label.Mui-focused {
    color: #BAA261 !important;
    font-family: 'Graphik-Regular' !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #BAA261 !important;
}
.login-input{
    width: 100%;
    height: 40px;
}