/*Bottom container*/
.bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    flex: 1 0 0%;
    width: 100%;
}
.bottom-container .wrapper{
    justify-content: flex-start;
    align-items: flex-start;
}
.container-menu {
    width: 308px;
    height: 624px;
    background-color: #0F232D;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    position:relative;
    box-sizing: border-box;
    padding: 30px;
}
.menu-item {
    display: flex;
    color: #BAA261;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 16px;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 46px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
    transition: all 250ms ease-in-out;
}
.all-users {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    flex: 1 0 0;
}
.menu-item img {
    width: 26px;
    height: 26px;
}
.icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 26px !important;
    height: 26px !important;
}
.menu-item.sub-menu-item {
    flex-direction: column;
}
.main-option{
    display: flex;
    color: white;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 44px;
}
.sub-menu .menu-item {
    min-height: 34px;
    padding: 0;
    font-family: 'Urbanist-SemiBold';
}
.sub-menu {
    width: 200px;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    box-sizing: border-box;
    position: relative;
}
.store-users {
    display: flex;
    border-top: 1px solid gray;
    flex-direction: column;
    position: relative;
    z-index: 1;
}
.store-menu-label {
    color: #ffffff70;
    height: 24px;
    display: flex;
    padding: 0 20px;
    align-items: flex-end;
    font-style: italic;
    font-size: 14px;
}
.selected-mark {
    width: 170px;
    background-color: #26D07C;
    height: 40px;
    border-radius: 0 20px 20px 0;
    position: absolute;
    z-index: 0;
    transition: all 250ms ease-in-out;
}

.goal-marker,
.store-marker {
    position: absolute;
    left: 30px;
    transition: all 250ms ease-in-out;
}
.goal-marker img,
.store-marker img {
    width: 16px;
    height: 16px;
}

/*Selected menu*/
.container-selected {
    box-sizing: border-box;
    height: 624px;
    width: 1012px;
    padding: 40px;
    overflow: hidden;
}
.left-container-selected {
    display: flex;
    flex-direction: column;
    height: 549px;
    width: 750px;
}
.top-menu-selected {
    display: flex;
    height: 40px;
    padding: 0 40px;
    align-items: flex-end;
}
.bottom-menu-selected {
    display: flex;
    flex-direction: column;
    height: 549px;
    width: 100%;
    background: white;
}
.header-selected {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px 0 20px;
    color: white;
}
.selected-title {
    font-size: 24px;
    font-family: 'Graphik-SemiBold';
    color: #000;
}
.top-menu-item {
    height: 40px;
    width: 200px;
    border-radius: 10px 10px 0 0;
    background: #101820;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 250ms  ease-in-out;
}
.container-selected .sortSelect .MuiOutlinedInput-root {
    border-radius: 10px;
    border: 0px solid white;
}
.container-selected .sortSelect div#demo-simple-select {
    padding: 8.5px 14px;
    color: white;
    box-sizing: border-box;
    height: 40px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 10px;
}
.container-selected .sortSelect p {
    position: absolute;
    left: -102px;
    top: 5px;
    font-size: 16px;
    color: black;
    font-family: 'Graphik-Regular';
    font-weight: 600;
}
.body-selected {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
/*List item*/
.list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 400px;
    position: relative;
}
.table-r-label.no-services {
    align-self: center;
    display: flex;
    position: absolute;
    top: 46%;
}
.list-headers {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    padding-right: 22px;
    box-sizing: border-box;
}
.header-column {
    flex: 1 0;
    overflow: hidden;
    color: white;
    font-family: 'Urbanist-SemiBold';
    align-items: center;
    justify-content: center;
    display: flex;
}
.list-list {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: 302px;
    width: 100%;
    padding-right: 4px;
    box-sizing: border-box;

}
.list-item {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    align-items: center;
    padding: 0 10px;
}
.item-column {
    color: white;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    font-family: 'Urbanist';
    height: 50px;
    display: flex;
    text-transform: initial;

    position: relative;
}
.list-list::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
    cursor: pointer;
}
.list-list::-webkit-scrollbar {
   cursor: pointer;
}
/*Resume container*/
.selected-option-container {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: clip;
}
.content-selected {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.performance-container {
    margin-bottom: 10px;
    display: flex;
    padding: 22px;
    background: #101820;
    border-radius: 20px;
    flex-direction: column;
    box-sizing: border-box;
    height: 360px;
}
.selected-option-container .right-container {
    display: flex;
    flex-direction: column;
    width: 310px;
    margin-left: 20px;
    color: white;
    margin-top: 40px;
}
.aliance-performance {
    display: flex;
    padding: 22px;
    background: #101820;
    border-radius: 20px;
    flex-direction: column;
    box-sizing: border-box;
    height: 244px;
}
.performance-title {
    font-size: 24px;
    text-align: left;
    font-family: 'Urbanist-SemiBold';
    margin-bottom: 10px;
}
.label-gary {
    font-family: 'Urbanist-Light';
    text-align: left;
    opacity: 0.5;
    margin-bottom: 4px;
}
.data-performance {
    text-align: left;
    font-family: 'Urbanist';
    margin-bottom: 10px;
}
.aliance-resume, 
.refered-resume {
    height: 500px;
    width: 100%;
    background-color: #101820;
    border-radius: 20px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
.switch-circle{
    position: absolute;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    transition: all 250ms ease-in-out;
}

.switch-bg {
    width: 70px;
    height: 44px;
    border-radius: 25px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;
}
.switcher-container {
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
}
.label-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.switch-label {
    font-family: 'Urbanist';
}
.find-allies-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
    padding: 20px 0 0;
    align-items: center;
}
.find-allies-content .data-performance {
    text-align: center;
}
.select-city-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ref-code-container {
    display: flex;
    border: 1px solid white;
    width: 254px;
    height: 74px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;
}
.container-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.icon-button-list{
    cursor: pointer;
}
.selected-paragraph {
    color: white;
    width: 460px;
    font-family: 'Urbanist-Light';
    margin-top: 18px;
    text-align: left;
    padding-left: 38px;
}
.sales-resume {
    width: 260px;
    height: 195px;
    background-color: #101820;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
}
.sales-resume-item,
.sales-resume-item-green {
    height: 120px;
    width: 100px;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
}
.sales-resume-item-green{
    border: 1px solid #26D07C;
}
.modal-buttons-container {
    width: 420px;
    display: flex;
    justify-content: space-around;
}

/*Catalog*/
.top-menu-catalog {
    height: 146px;
    width: 1080px;
    background-color: #101820;
    border-radius: 25px;
    display: flex;
    box-sizing: border-box;
    padding: 20px 40px;
    justify-content: flex-start;
    align-items: center;
}
.left-container-catalog {
    color: white;
}
.top-left .selected-title {
    padding-left: 0px;
    text-align: left;
}
.top-left,
.top-mid {
    margin-right: 70px;
}
.new-product-container{
    padding: 10px 15px;
    height: 470px;
    width: 280px;
    box-sizing:border-box;
}
.new-product {
    height: 447px;
    width: 250px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 1px solid black;
    padding: 20px;
    cursor: pointer;
}
.hover-border{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    transition: all 250ms ease-in-out;
    border: 1px solid #26d07c00;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
}
.new-product:hover .hover-border{
    border: 1px solid #26d07c;
}
.bottom-menu-catalog .resultPage {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 60px;
    justify-content: left;
    box-sizing: border-box;
    width: 960px;
}
.bottom-menu-catalog {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: auto;
}
/*Create product*/
.form {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.create-product {
    width: 1080px;
    display: flex;
    flex-direction: column;
    height: 900px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 2px 12px 5px rgb(0 0 0 / 15%);
    box-sizing: border-box;
    justify-content: center;
    align-items: flex-start;
}
.back-btn {
    display: flex;
    width: 109px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    font-family: 'Urbanist';
}
.back-header {
    display: flex;
    height: 50px;
}
.title-create {
    padding-left: 70px;
    font-size: 24px;
    font-family: 'Urbanist-SemiBold';
    height: 40px;
    display: flex;
    align-items: center;
}
.create-body form {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 35px;
    box-sizing: border-box;
}
.left-form, .right-form {
    display: flex;
    flex-direction: column;
    width: 420px;
    justify-content: space-between;
}
.crate-box {
    width: 420px;
    height: 50px;
    /* border-radius: 20px; */
}
.create-body .supplierSelect {
    margin-top: 0;
    height: 50px;
    width: 420px;
}
.create-body .select-container .MuiOutlinedInput-root {
    border-radius: 25px;
    height: 50px;
}
.text-field-form {
    height: 50px;
    width: 420px;
}
.text-field-form label{
    line-height: 1em;
}
.text-field-form .MuiOutlinedInput-root {
    border-radius: 25px !important;
    font-family: 'Urbanist' !important;
    height: 50px;
}
div#demo-simple-select {
    padding: 8.5px 14px;
    color: #000000;
    font-family: 'Urbanist';
    text-align: left;
}
#menu- .MuiMenu-paper{
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 6px;
}
#menu- .MuiMenu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    outline: 0;
    width: 100%;
    overflow-y: scroll;
    height: 200px;
    /* text-align: center; */
}
#menu- .MuiMenuItem-root {
    border-bottom: 1px solid #00000020;
    height: 50px;
    justify-content: center;
    font-family: 'Urbanist';
}

.create-body {
    width: 100%;
    display: flex;
}
.radio-flex {
    flex-direction: row !important;
    color: black;
    font-family: 'Urbanist';
}
.radio-flex span.Mui-checked {
    color: #26d07c;
}
.radio-label {
    color: black !important;
    font-family: 'Urbanist' !important;
}
.lookingForSelect {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    padding-left: 0;
}
.lookingForSelect fieldset {
    /* display: flex; */
    text-align: left;
}
.lookingForSelect fieldset .Mui-focused {
    /* display: flex; */
    color: black;
}
.lookingForSelect fieldset {
    /* display: flex; */
    color: black;
}
.radio-flex span {
    font-family: 'Urbanist';
}
.create-body .form-group {
    font-family: 'Urbanist';
    text-align: left;
}
.create-body textarea.text-area-esa {
    width: 100%;
    height: 154px;
}
.right-aling {
    align-self: flex-end;
    justify-self: flex-end;
}
.left-align {
    align-self: flex-start;
}
.create-body .form-content {
    margin-bottom: 25px;
    padding-top: 10px;
}
.photo-container {
    width: 90px;
    height: 90px;
    border: 1px solid #a3a3a3;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: grab;
}
.add-photo{
    cursor: pointer;
}
.gallery {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}
.slider-create {
    max-width: 200px;
    margin-left: 13px;
    color: #26d07c !important;
    margin-right: 50px;
}
.text-field-form-mini .MuiOutlinedInput-root {
    border-radius: 25px !important;
    font-family: 'Urbanist' !important;
    height: 50px;
}
.text-field-form-mini label{
    line-height: 1em;
}
.text-field-form-mini {
    height: 50px;
    max-width: 100px;
}
.photo-container img {
    width: 90%;
    height: 90%;
    object-fit: contain;
    pointer-events: none;
}
.add-photo input[type="file"] {
    position: absolute;
    width: 100px;
    height: 100px;
    opacity: 0;
    cursor: pointer;
}
.portada-tag {
    position: absolute;
    background-color: #26d07c;
    border-radius: 20px;
    padding: 4px;
    /* align-self: flex-start; */
    display: flex;
    bottom: -28px;
    left: 50%;
    transform: translate(-50%,-50%);
    /* opacity: 1; */
    color: white;
}
.gallery .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.delete-tag {
    position: absolute;
    top: -10px;
    right: -10px;
    /* width: 10px; */
    /* height: 10px; */
}
.create-body .lookingForSelect .MuiTypography-root {
    color: black;
}
.container-left-details {
    width: 100%;
    border-radius: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    position: relative;
    z-index: 1;
}
.details-form-container {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.container-right-details {
    width: 420px;
    border-radius: 20px;
    margin-left: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
}
.container-form-data {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    margin-top: 10px;
}
.container-form-title {
    height: 60px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-family: 'Urbanist-SemiBold';
    font-size: 20px;
}
.details-form-container .form-content {
    width: 100%;
    /* max-width: 420px; */
    display: flex;
    justify-content: space-around;
}
.form-group-text-area {
    width: 100%;
    height: auto;
    position: relative;
}
textarea.text-area-esa {
    width: 420px;
    border-radius: 25px;
    height: 97px;
    resize: none;
    font-size: 16px;
    padding: 20px 14px;
    box-sizing: border-box;
    font-family:'Urbanist';
    border: #8080807a solid 1px !important;
    outline: unset;
    color: #9f9f9f;
    background-color: transparent;
    /* overflow-y: scroll; */
}

.details-form-container .form-label {
    height: 18px;
    text-align: left;
    font-size: 12px;
    padding-left: 16px;
    color: #b7b7b7;
}
.container-back-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #26d07c;
    cursor: pointer;
    margin-top: 20px;
}
.container-right-options {
    background-color: #101820;
    width: 100%;
    height: 400px;
    border-radius: 20px;
    color: white;
    font-family: 'Urbanist';
    padding: 20px;
    box-sizing: border-box;
}
.line-deco {
    height: .5px;
    width: 100%;
    background: white;
    margin-top: 10px;
    margin-bottom: 30px;
}
.container-right-title {
    text-align: left;
    height: 60px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: 'Urbanist-SemiBold';
}
.fullwidth {
    width: 100%;
}
.fullwidth >div {
    border-radius: 10px;
    height: 40px;
}
.finderTop>div:first-child {
    position: relative;
}
.search-icon {
    position: absolute !important;
    right: 0px;
    top: -2px;
}

.item-marker {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: white;
    right: 4px;
}
.pending {
    background: rgb(253, 246, 36);
}
.done {
    background: #26D07C;
}

.date-picker {
    border-radius: 25px !important;
    margin-bottom: 20px !important;; 
    height: 50px !important;
}
.date-picker label {
    color: #26D07C !important;
    font-family: 'Urbanist-SemiBold';
    font-size: 16px !important;
}
.date-picker .MuiInputBase-formControl.MuiInputBase-adornedEnd {
    height: 50px !important;
    border-radius: 25px !important;
}
.date-picker input {
    color: #26D07C !important;
    font-family: 'Urbanist-SemiBold' !important;
}
.date-picker button {
    background: #26D07C !important;
}
/*COntainer resume*/
.slider-container {
    height: 224px;
    width: 100%;
    margin-bottom: 40px;
    padding-right: 20px;
    box-sizing: border-box;
}
.main-b-title {
    font-family: 'Graphik-Bold';
    font-size: 16px;
    text-align: left;
    margin-bottom: 30px;
}
.slider-container .slider {
    height: 174px;
    width: 100%;
    background-color: beige;
    border-radius: 10px;
}
.last-activity-finished {
    display: flex;
    flex-direction: column;
}
.table-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;
}
.table-h-label {
    flex: 1 0 0%;
    font-family: 'Graphik-Semibold';
}
.table-content {
    flex-direction: column;
    display: flex;
    width: 100%;
    position: relative;
}
.table-contents {
    flex-direction: column;
    display: flex;
    width: 100%;
    position: relative;
    min-height: 400px;
}
.table-row {
    width: 100%;
    flex-direction: row;
    display: flex;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 10px;
    height: 40px;
    align-items: center;
    margin-bottom: 10px;
}
.table-r-label {
    flex: 1 0 0%;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* justify-content: left; */
    display: block;
}

.note-item.receive .table-r-label{
    text-align:left;
 text-overflow: unset;
    overflow: unset;
    white-space: unset;
    max-height: unset;
}

.table-r-label-f {
    flex: 1 0 0%;
    justify-content: center;
    /* justify-content: left; */
    display: flex;
}
.last-a-container {
    margin-bottom: 40px;
}
.finderTop {
    margin-bottom: 20px;
}
.resultSortBtn {
    margin-top: 20px;
    position: relative;
    width: 200px;
    height: 40px;
}
.youtube-container {
    display: flex;
    flex: 1 0 0%;
    justify-content: flex-start;
    align-items: center;
}
.content-info {
    height: 100px;
    width: 810px;
    border-radius: 10px;
    background-color: #0F232D;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.main-b-sub-title {
    font-family: 'Graphik-Regular';
    color: black;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 8px;
}
.info-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
}
.data-label {
    color: white;
    font-family: 'Graphik-Semibold';
    font-size: 16px;
}
.data-content {
    color: white;
    font-family: 'Graphik-Regular';
    font-size: 16px;
}
.phone-data {
    width: 155px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid white;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 20px;
    overflow: hidden;
}
.email-data {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 20px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.svg-container {
    position: absolute;
    right: 16px;
    bottom: -4px;
}

.svg-container.phone {
    right: 0px;
    bottom: -24px;
}

.phone-contact-label {
    justify-content: flex-start;
    display: flex;
    align-items: flex-end;
    font-family: 'Graphik-Regular';
    color: white;
    font-size: 25px;
    font-weight: 900;
    position: relative;
    margin-top: 7px;
}

.sales .svg-container {
    right: 67px;
}
.accounting .svg-container {
    right: 42px;
}
.container-left-details .form-group {
    width: 25%;
    height: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
}
.container-left-details .form {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: column;
}
.create-project .form{
    height: 260px;
}
.slide-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 10px;
    box-sizing: border-box;
}
.MuiSlider-track{
    color: #aa9461;
}
.MuiSlider-thumb{
    background-color: #aa9461 !important;
}
.MuiSlider-markLabel{
    font-family: 'Graphik-Regular' !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}
.detail-title {
    font-size: 24px;
    font-family: 'Graphik-SemiBold';
    text-align: left;
    padding-left: 10px;
}
.description-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 10px;
    box-sizing: border-box;
}
textarea.text-area-detail {
    width: 100%;
    border-radius: 20px;
    height: 90px;
    resize: none;
    font-size: 16px;
    padding: 20px 14px;
    box-sizing: border-box;
    font-family: 'Graphik-Regular';
    border: #8080807a solid 1px !important;
    outline: unset;
    color: #9f9f9f;
    background-color: transparent;
    margin-top: 10px;
    /* overflow-y: scroll; */
}
.itemCatContainer {
    height: 340px;
    overflow: hidden;
}
.note-item {
    height: auto;
    background: rgb(225, 225, 225);
    border-radius: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
    margin-top: 20px;
    
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    align-items: flex-end;
    align-self: flex-end;
}
.note-item.receive {
    align-items: flex-start;
    align-self: flex-start;
}
.note-item .table-r-label{
    padding-left: 8px;
    margin-top: 4px;
}
.profile-view .form-content {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding-right: 30px;
}
.table-r-label.time {
    font-size: 12px;
    font-family: 'Graphik-Regular-Italic';
    opacity: 0.5;
    align-self: flex-start;
    position: absolute;
    bottom: 4px;
}
.receive .table-r-label.time {
    align-self: flex-end;
}
.profile-view .form-group {
    width: 22%;
}
.profile-view .form {
    padding-right: 20px;
    box-sizing: border-box;
    flex-direction: column;
}
.project-list-container {
    border-top: 2px solid black;
    padding-top: 20px;
    height: auto;
    /* overflow-y: auto; */
    min-height: 400px;
    /* padding-right: 20px; */
    box-sizing: border-box;
    width: 96%;
}
.project-item {
    height: 140px;
    width: 244px;
    border-radius: 10px;
    border: 1px solid #aa9641;
    align-items: center;
    justify-content: center;
    display: flex;
    transform: scale(1);
    cursor: pointer;
    transition: all 250ms ease-in-out;
    margin-bottom: 20px;
    flex-direction: column;
    position: relative;
    z-index: 1;
}
.btn-new-label {
    font-family: 'Urbanist';
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.project-item:hover {
    transform: scale(1.05);
}
.project-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-evenly;
}
.icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0f232d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
}
.icon span {
    height: 38px;
}
.back-btn-container {
    position: absolute;
    top: -10px;
    width: 100%;
    z-index: 2;
}
.back-btn-modal {
    position: absolute;
    top: 10px;
    left:10px;
    width: 96%;
    z-index: 2;
}
.back-btn-tf {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #1b3541;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.autocomplete-full{
    width: 96%;
}

.multiline {
    width: 100%;
}
.form-group.multiline-form {
    width: 50%;
}
p#outlined-basic-helper-text {
    position: absolute;
    bottom: -40px;
}
.detail-form-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 140px;
    flex-direction: column;
}
.detail-service-project .detail-form-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    flex-direction: column;
}
.last-a-container {
    margin-bottom: 40px;
    padding: 0 20px 0 0;
    box-sizing: border-box;
}
.last-f-container {
    padding: 0 20px 0 0;
    box-sizing: border-box;
}
.profile-data{
    margin-bottom: 20px;
}
.selected-option-container.home{
    overflow-x: unset;
    overflow-y: unset;
}
.detail-header {
    display: flex;
    height: auto;
    justify-content: center;
}
.btn-cancel {
    position: absolute;
    right: 20px;
    top: 0;
    cursor: pointer;
}
.profile-view .detail-form-title,
.create-project .detail-form-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    flex-direction: row;
}
.profile-view .container-form-data,
.create-project .container-form-data {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    margin-top: 0px;
}
.profile-view .detail-header,
.create-project .detail-header {
    display: flex;
    height: auto;
    justify-content: flex-start;
}
img.image-viewer {
    width: 200px;
    height: 200px;
}
.itemCatContainer {
    height: 230px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.category-canceled {
    background-color: #b83b07 !important;
}
.category-pending {
    background-color: #ac9f0e !important;
}
.category-proposal {
    background-color: #0c495a !important;
}
.category-underway {
    background-color: #0792b8 !important;
}
.category-scheduled {
    background-color: #97176a !important;
}
.category-finished {
    background-color: #28982e !important;
}
.marker-color {
    width: 12px;
    height: 12px;
    margin-right: 8px;
}
.markers {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.marker-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.marker-label {
    font-family: 'Graphik-Regular';
}
.content-selected.centered{
    justify-content: center;
    align-items: center;
}
.all-users.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.container-navbar.disabled {
    pointer-events: none;
    /* opacity: 1.5; */
}
img.image-modal-big {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.content-selected {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.manager-dashboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}
.manager-card {
    width: 300px;
    height: 200px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    flex-direction: column;
    cursor:pointer;
    transform: scale(1);
    transition: all 250ms ease-in-out;

}
.manager-card:hover {
    transform: scale(1.02);

}
.manager-p.back-btn-container {
    top: 30px;
}
.manager-card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 53, 65, 0.95);
    border: 1px solid #aa9461;
    z-index: 0;
    box-sizing: border-box;
    border-radius: 10px;
}
.manager-label, .manager-counter {
    /* display: flex; */
    position: relative;
    z-index: 1;
}
.manager-label{
    font-family: 'Graphik-Regular';
    color: white;
    font-size: 14px;
}
.manager-counter{
    font-family: 'Graphik-Bold';
    color: #aa9461;;
    font-size: 48px;
}
.MuiModal-root .cancelBtn {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.btn-container-manager {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.table-row.pending-row {
    border-color: #aa9641;
    border-width: 2px;
    position: relative;
}
.table-row.pending-row::after {
    content: 'New';
    width: 50px;
    height: 26px;
    border-radius: 20px;
    background-color: #aa9641;
    color: white;
    font-family: 'Graphik-Regular';
    position: absolute;
    top: -10px;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
input.swal2-file {
    height: 29px;
}
.table-h-label {
    text-transform: capitalize;
}
.send-form-container {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    box-sizing: border-box;
}
.delete-icon {
    width: 30px;
    height: 30px;
    background: #d62828;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: scale(1);
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.delete-icon:hover{
    transform: scale(1.2);
}

.project-name {
    height: 80%;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.project-l-m {
    font-family: 'Graphik-Semibold';
}
.project-l {
    font-family: 'Graphik-Regular';
}
.header-and-btn {
    display: flex;
}
.btn-create-service {
    position: relative;
    display: flex;
    margin-left: 20px;
    top: -7px;
}
.btn-create-service .icon {
    width: 60px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.form-content {
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
}
.proposal-list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.proposal-pdf-container {
    display: flex;
    width: 160px;
    height: 150px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    transform: scale(1);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.proposal-pdf-container:hover {
    transform: scale(1.02);
}
.log-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 8px;
    border: 1px solid #0000007d;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 6px;
}
.log-date {
    display: flex;
    align-self: flex-end;
    font-size: 11px;
    font-family: 'Graphik-Regular';
    /* text-transform: capitalize; */
}
.log-user {
    font-family: 'Graphik-SemiBold';
    font-size: 14px;
}
.log-data{
    font-family: 'Graphik-Regular';
    font-size: 14px;
    text-align: justify;
    margin-top: 4px;
}
textarea.swal2-textarea {
    height: 57px;
    resize: none;
    font-family: 'Graphik-Regular';
    font-size: 16px;
}

.new-slider {
    border: 1px solid #1b3541;
    border-radius: 4px;
    height: 120px;
    display: flex;
    justify-content: center;
    font-family: 'Graphik-Bold';
    font-size: 40px;
    align-items: center;
    color: #a99460;
    cursor: pointer;
    transform: scale(1);
    transition: all 250ms ease-in-out;
}

.new-slider:hover {
    transform: scale(1.02);
}

.btn-cancel.slider {
    position: relative;
    right: 0;
}

.slider-form .form-content {
    display: flex;
    flex-direction: row;
    /* gap: 6px; */
    justify-content: space-between;
    flex: 1;
}

.slider-form .form-group {
    max-width: 200px;
}

.butons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.new-slider-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.slider-preview {
    height: 160px;
    background-color: #e7e3e3;
    border-radius: 4px;
    display: flex;
    box-sizing: border-box;
    padding: 10px 80px;
    gap: 6px;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.slider-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1b3541c7 30%, #f69d3c00);
    z-index: 1;
}

.slider-title {
    font-family: 'Graphik-Semibold';
    font-size: 20px;
    position: relative;
    z-index: 2;
    color: #a08d5e;
}

.slider-paragraph {
    font-family: 'Graphik-Regular';
    position: relative;
    z-index: 2;
    max-width: 290px;
    color: white;
    text-align: justify;
}

.slider-btn {
    height: 30px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #baa162;
    border-radius: 4px;
    color: white;
    align-self: center;
    cursor: pointer;
    transform: scale(1);
    transition: all 250ms ease-in-out;
    position: relative;
    z-index: 2;
}

a {
    text-decoration: none;
    font-family: 'Graphik-Semibold';
    color: white;
}

.slider-btn:hover{
    transform: scale(1.02);
}

.current-sliders {
    margin-top: 20px;
}

.sliders-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
}
