
.new-pass-container {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.new-pass-container .content-login{
    display:flex;
    text-align: center;
    box-sizing:border-box;
    position: relative;
    width: 460px;
    height: 500px;
    background: #FFFFFF;
    border: 1px solid black;
    border-radius: 10px;
    flex-direction: column;
}
.new-pass-container .content-login form.form {
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    padding: 30px 40px;
    display: flex;
    height: 376px;
    justify-content: flex-start;
}