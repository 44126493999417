.register-container {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.register-container .content-login{
    display:flex;
    text-align: center;
    box-sizing:border-box;
    position: relative;
    width: 460px;
    height: 664px;
    background: #FFFFFF;
    border: 1px solid black;
    border-radius: 10px;
    flex-direction: column;
}

.register-container a {
    color: #baa262;
    font-size: 14px;
}

.form-group.log-back {
    justify-content: flex-start;
}
.register-container .content-login form.form {
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    padding: 30px 40px;
    display: flex;
    height: 550px;
    justify-content: flex-start;
}
.log-back span {
    margin-right: 6px;
}