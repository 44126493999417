.navbar-container-logged{
    display: flex;
    height:130px;
    flex:1 0 0%;
    background-color:#1B3541;
    color:white;
    justify-content:center;
    align-items:center;
    z-index:1;
    position: relative;
    width:100%;
    opacity:0.75;
    transition: all 250ms ease-in-out;
}
.navbarContent{
    display: flex;
    flex:1 0 0%;
    flex-direction: row;
    max-width: 1300px;
    justify-content: space-between;
}
.menuLeft{
    display: flex;
    flex-direction: row;
}
.menuRight{
    display: flex;
    flex-direction: row;
}
.navbarItem{
    display: flex;
    padding: 0 20px;
    align-items:center;
    justify-content: center;
    cursor: pointer;
}
.menuLeft .navbarItem:first-child {
    padding: 0 20px 0 0;
} .menuRight .navbarItem:last-child  {
    padding: 0 0 0 20px;
}
.centeredText{
    position: absolute;
    transform:translate(-50%,-50%);
    top:50%;
    left:50%
}
.link{
    color:white !important;
    text-decoration:none;
    font-family: Urbanist;
    font-size: 16px;
}
.link a {
    color: white !important;
    text-decoration: none;
    font-family: Urbanist;
    font-size: 16px;
    transition:  all 200ms ease-in-out;
}
.link a.active, .link a:hover {
    color: #26d07c !important;
}
.navbarContent .MuiOutlinedInput-root {
    border-radius: 25px;
    border: none;
}
.navbarContent .finderTop {
    display: flex;
    width: 100%;
    flex-direction: revert;
    align-items: center;
    justify-content: center;
}
.navbar-container-logged .navbar-links-container {
    width: 280px;
}
.user-name-regular {
    font-family: 'Graphik-Regular';
    color: #BAA261;
    position: relative;
    cursor: pointer;
}
.user-name-bold {
    font-weight: 800;
}
.nav-menu {
    position: absolute;
    background-color: #1b3541;
    height: 60px;
    width: 200px;
    transition: all 250ms ease;
    bottom: -40px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
}
.nav-menu.active{
    opacity: 1;
    bottom: -60px;
}
.nav-menu-item{
    font-family: 'Graphik-Regular';
    color: #aa9461;
}
.logo-container {
    cursor: pointer;
}