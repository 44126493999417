.free-booking-container {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.free-booking-container .booking-component{
    display:flex;
    text-align: center;
    box-sizing:border-box;
    position: relative;
    width: 460px;
    height: 664px;
    background: #FFFFFF;
    border: 1px solid black;
    border-radius: 10px;
    flex-direction: column;
}
.modal-register {
    height: 100%;
    width: 100%;
    position: relative;
}
.step {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    box-sizing: border-box;
}
.step-form form.form {
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    height: 100%;
}
.logged .step-form {
    display: flex;
    flex-direction: column;
    padding: 22px 50px;
    flex: 1 0 1%;
}
.step-form button.loginBtn {
    align-self: center;
}
.step-navigator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.step-marker {
    height: 120px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-bottom: 24px;
    /* align-self: center; */
}
.step-mark {
    height: 12px;
    width: 12px;
    background-color: rgb(201, 201, 201);
    border-radius: 50%;
}
.step-mark.active{
    background-color: #101820;
}
.free-booking-container .step .back-header {
    display: flex;
    height: 18px;
}
.stepBtn {
    position: absolute;
    top: 20px;
    left: 20px;
}
.form-group.area {
    height: 200px;
}
textarea.text-area-tf {
    width: 420px;
    border-radius: 10px;
    height: 156px;
    resize: none;
    font-size: 16px;
    padding: 20px 14px;
    box-sizing: border-box;
    font-family: 'Urbanist';
    border: #8080807a solid 1px !important;
    outline: unset;
    color: #9f9f9f;
    background-color: transparent;
    /* overflow-y: scroll; */
}
.back-btn button {
    height: 28px;
    width: 28px;
}
.logged .step-marker {
    height: 20px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-bottom: 24px;
    /* align-self: center; */
}
.project-card {
    height: 115px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #aa9461;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    transform: scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-r-label {
    max-height: 40px;
}
.projects-slider {
    /* flex: 1 0; */
    /* display: flex; */
    /* flex-direction: column; */
    max-height: 406px;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
.project-card:hover{
    transform: scale(1.02);
}
.project-label {
    font-family: 'Graphik-Semibold';
}
.logged .step-2 .form-content {
    overflow-y: scroll;
    max-height: 455px;
    padding: 0 20px;
    box-sizing: border-box;
}
.logged textarea.text-area-tf {
    width: 100%;
    /* overflow-y: scroll; */
}
.logged .area{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.logged .area p.MuiFormHelperText-root {
    align-self: flex-start;
    padding-left: 16px;
}
.create-propertie {
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: space-around;
    margin-top: 14px;
}
.logged form.form {
    overflow-y: auto;
    max-height: 424px;
    padding-right: 10px;
}
.logged .autocomplete-full {
    width: 100%;
}
.btn-container-logged {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.step-form.step-2 form.form {
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    height: 100%;
    max-height: unset;
}
.project-card.picked {
    background-color: #1b3541;
    color: white;
}
.step-header {
    font-family: 'Graphik-Regular';
    text-transform: capitalize;
}
.login-input label {
    text-transform: capitalize;
    font-family: 'Graphik-Regular';
}
.step-header {
    font-family: 'Graphik-SemiBold';
    text-transform: capitalize;
    display: flex;
    justify-content: center;
}