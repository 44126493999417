.container-navbar {
    height: 130px;
    width: 100%;
}
.container-landing {
    width: 100%;
    height: 630px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.landing-bg {
    position: absolute;
    width: 100%;
    height: 100%;
}
img.bg-landing-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.bg-text {
    background-color: white;
    color: #BAA261;
    font-size: 64px;
    font-family: 'Graphik-Regular';
    font-weight: 900;
    padding: 4px;
}
.normal-text{
    color: #BAA261;
    font-size: 64px;
    font-family: 'Graphik-Regular';
    font-weight: 900;
    margin-left: 18px;
    text-shadow: 1px 1px 1px #ffffff8c;
}
.landing-content {
    position: relative;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 160px;
}
.loginBtn.landing {
    margin-top: 30px;
}
.bg-cover {
    background-color: #00000040;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.login-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 1000px;
    height: 200px;
    align-items: center;
    font-family: 'Graphik-Regular';
}
.loginCardBtn.landing {
    width: 300px;
    height: 108px;
    font-family: 'Graphik-SemiBold';
    text-transform: capitalize;
    background-color: rgba(186, 162, 97, 0.85);
    transition: all 250ms;
    font-size: 16px;
}
.loginCardBtn:hover {
    background-color:  rgba(186, 162, 97, 1) !important;
}