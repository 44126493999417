@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";

@include textfield.core-styles;
html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  background-color: #1B3541;;
}
section.components {
  overflow-x: hidden;
}
.container-navigation {
  display: flex;
  flex: 1 0 0%;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.bigCircle {
  position: absolute;
  transform: translate(-50%,-100%);
  left: 50%;
  top: 680px;
  border-radius: 50%;
  background-color: #101820;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  align-items: center;
  padding-bottom: 80px;
}
section.components {
  background-color: white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
input#input-with-sx {
  max-height: 40px;
  height: 40px;
  box-sizing: border-box;
}
.mainBtn {
  width: 220px!important;
  height: 40px!important;
  background-color: #26d07c !important;
  color: black !important;
  font-family: 'Urbanist' !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  transition: all 250ms ease-in-out !important;
  border-radius: 25px !important;
  margin-bottom: 20px !important;
  text-transform: initial !important;
  font-family: Urbanist !important;
  padding: 0 !important;
}
.mainBtn:hover{
  transform:scale(1.03);
  background-color: rgb(70, 216, 143) !important;
}
.listItemBtn {
  width: 157px!important;
  height: 33px!important;
  background-color: #101820 !important;
  color: #26d07c !important;
  font-family: 'Urbanist-SemiBold' !important;
  font-size: 16px !important;
  transition: all 250ms ease-in-out !important;
  border-radius: 30px !important;
  text-transform: initial !important;
  padding: 0 !important;
  border: 3px solid white !important;
  box-sizing: border-box !important;
}
.listItemBtn:hover{
  background-color: rgba(255, 255, 255, 0.207) !important;
}

.listItemBtn.disabled{
  background-color: #101820 !important;
  color: rgba(255, 255, 255, 0.288) !important;
  border: 3px solid rgba(255, 255, 255, 0.288) !important;
  box-sizing: border-box !important;
  cursor: disabled !important
}
svg path {
  transition: fill 250ms ease-in;
}
.hover-border svg path {
  transition: stroke 1s ease-in-out;
}
.modalBlackBtn {
  width: 160px!important;
  height: 40px!important;
  background-color: #101820 !important;
  color: #ffffff !important;
  font-family: 'Urbanist-SemiBold' !important;
  font-size: 16px !important;
  transition: all 250ms ease-in-out !important;
  border-radius: 30px !important;
  text-transform: initial !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
.modalBlackBtn:hover{
  background-color: #315A84 !important;
}
.cancelBtn {
  background-color: #1B3541 !important;
  width: 165px;
  height: 48px;
  padding: 0;
  color: white !important;
  text-transform: initial !important;
  /* font-family: 'Graphik-Regular'; */
  /* color: white; */
}
.modalGreenBtn {
  width: 220px!important;
  height: 40px!important;
  background-color: #26d07c !important;
  color: #000000 !important;
  font-family: 'Urbanist-SemiBold' !important;
  font-size: 16px !important;
  transition: all 250ms ease-in-out !important;
  border-radius: 30px !important;
  text-transform: initial !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
.modalGreenBtn:hover{
  transform: scale(1.03);
  background-color: #3BED95 !important;
}

.addProductBtn {
  width: 220px!important;
  height: 40px!important;
  background-color: #26d07c !important;
  color: white !important;
  font-family: 'Urbanist' !important;
  font-size: 16px !important;
  transition: all 250ms ease-in-out !important;
  border-radius: 25px !important;
  margin-bottom: 20px !important;
  text-transform: initial !important;
  font-family: Urbanist !important;
  padding: 0 !important;
}
.addProductBtn:hover{
  transform:scale(1.03);
  background-color: #315A84 !important;
}

.green{
  color: #aa9261;
}
.link-green{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  color:#aa9261;
  cursor: pointer;
}
.link-green a{
  color:#aa9261;
  text-decoration: unset;
}
.gray{
  color: #00000050;
}
.normal-label {
  font-size: 16px;
  font-family: 'Urbanist';
}
.TF-label{
  font-size: 24px;
  font-family: 'Urbanist';
}
.bold{
  font-family: 'Urbanist-Bold';
}
.demi-bold{
  font-weight: 600;
}
.css-ohwg9z {
  position: relative;
  overflow: hidden;
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #00000060; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00000050; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00000090; 
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}
.swal2-popup {
  width: 440px !important;
  height: 270px !important;
}
h2.swal2-title {
  font-family: 'Graphik-SemiBold' !important;
  font-size: 16px !important;
  color: black !important;
  margin-top: 20px !important;
}
.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-deny:focus {
  box-shadow:unset !important;
}
button.swal2-confirm,
button.swal2-deny
 {
  width: 165px !important;
  height: 48px !important;
  background-color: #aa9461 !important;
  font-family: 'Graphik-SemiBold' !important;
}
.swal2-html-container {
  font-family: 'Graphik-Regular' !important;
  font-size: 16px !important;
  color: black !important;
}

.rbc-today {
  background-color: #407e9b7e !important;
}
.rbc-header {
  background-color: #1B3541 !important;
  height: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #aa9461 !important;
  border-color: #aa94613e !important;
  border-width: 2px !important;
}
.rbc-header span{
  font-family: 'Graphik-Regular' !important;
}
.rbc-day-bg, .rbc-day-bg {
  border-color: #aa94613e !important;
  border-width: 2px !important;
}
.rbc-month-row {
  border-color: #aa94613e !important;
  border-width: 2px !important;
}
.rbc-month-view{
  border-color: #aa94613e !important;
  border-width: 2px !important;
}
.rbc-date-cell {
  display: flex;
  justify-content: center;
  padding: 8px !important;

  /* font-family: 'Graphik-SemiBold' !important; */
}
.rbc-day-bg{
  cursor: pointer !important;
}
.rbc-date-cell button{
  font-family: 'Graphik-Regular' !important;
  font-weight: 600 !important;
  cursor: unset !important;
}
.rbc-btn-group button {
  color: white !important;
  background-color: #1b3541 !important;
  padding: 4px 16px !important;
  font-family: 'Graphik-Regular' !important;
  border-color: #aa94613e !important;
  cursor: pointer !important;
}
.rbc-btn-group button:hover {
  color: white !important;
  background-color: #2d5568 !important;
  border-color: #aa94613e !important;
  cursor: pointer !important;
}
.rbc-btn-group{
  position: absolute !important;
  left: 0;
  top: 0;
}
.rbc-calendar{
  position: relative !important;
}
span.rbc-toolbar-label {
  font-family: 'Graphik-Semibold' !important;
  color: #1B3541 !important;
}
.rbc-month-row{
  cursor: pointer !important;
}
